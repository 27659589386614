import { css } from '@emotion/react'
import { FAQJsonLd, FAQJsonLdProps } from 'gatsby-plugin-next-seo'
import React from 'react'
import { FaqItems } from '../components/contents/FaqItems'

import Single from '../components/contents/Single'
import faqs from '../data/faq.json'
import { space } from '../styles/variables.styles'
import NotFoundPage from './404'

type Props = {
  location: {
    [key: string]: string
  }
}

const FaqPage = ({ location }: Props) => {
  const isEmpty = () => {
    if (Object.keys(faqs).length === 0 && faqs.constructor === Object) {
      return true
    }
    if (
      faqs.categories.length === 1 &&
      Object.keys(faqs.categories[0]).length === 0 &&
      faqs.categories[0].constructor === Object
    ) {
      return true
    }
    return false
  }

  if (isEmpty()) {
    return <NotFoundPage />
  }
  // for FAQJsonLd
  const faqList: FAQJsonLdProps['questions'] = faqs.categories.flatMap((category) => {
    return category.items.map((item) => {
      return {
        question: item.title,
        answer: item.body
      }
    })
  })

  return (
    <>
      <FAQJsonLd questions={faqList} />
      <Single
        slug="faq"
        readText={
          <>
            代表的なご質問事例です。その他のご質問については、
            <br className="is-lg-only" />
            お気軽にお問い合わせください。
          </>
        }
        pathname={location.pathname}
      >
        <div css={faqStyle}>
          <FaqItems faqCategories={faqs.categories} />
        </div>
      </Single>
    </>
  )
}

export default FaqPage

const faqStyle = css`
  margin-top: clamp(calc(${space.md}* 2), 5vw, calc(${space.md} * 4));
`
